body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/* SCROLL */
::-webkit-scrollbar { width: 8px; height: 8px;}
/* 스크롤바의 width */
::-webkit-scrollbar-track { background-color: #f0f0f0; }
/* 스크롤바의 전체 배경색 */
::-webkit-scrollbar-thumb { 
  height: 30%; /* 스크롤바의 길이 */
    background:#adabab;/*linear-gradient(to bottom, #1697bf, #62b7ac); */
    border-radius: 10px;
}
/* 스크롤바 색 */
::-webkit-scrollbar-button { display: none; }

input,textarea {
  outline: none;
}


body{
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  
  /* padding: 20px; */

}
pre{
  font-family: Arial, sans-serif;
}
/* #organization .title{
  border-bottom: 1px solid #eaeaea;
} */
#organization .header{
  font-family: '맑은고딕','Malgun Gothic';
  font-size: x-large;
  font-weight: bold;
  color:#072a5a;
  margin:5px;
  
}
#organization .treechart {
  font-family: '맑은고딕','Malgun Gothic';
  max-width: 1100px;
  margin: 30px auto;
  text-align: left;
}

/* 각 box */
#organization .treechart .box {
  position: relative;
  width: 260px;
  padding: 12px;
  border-radius: 6px;
  background: #29538d;
  color: #fff;
  text-align: center;
  font-weight: 600;
}
#organization .box:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* 각 박스에서 나오는 선 일단 생성 */
#organization .treechart .box::after {
  content:"";
  display: block;
  position: absolute;
}

#organization .treechart .box.lv1 {
  background: #29538d;
}

#organization .treechart .box.lv2 {
  margin-top: 40px;
  background: #29538d;
}

#organization .treechart ul {
  position: relative;
  margin-top: 40px;
}

/* 플로트 망가지는거 방지 */
#organization .treechart ul::after {
  content:"";
  display: block;
  clear: both;
}

#organization .treechart ul li {
  position: relative;
}

#organization .treechart ul li + li {
  position: absolute;
  top: 0;
  left: 360px;
}

/* 마지막 레벨 */
#organization .treechart ul.lvLast li {
  position: relative;
  left: unset;
  top: unset;
  width: 23%;
  float: left;
  background-color: white;
  border-radius: 8px;
}

#organization .treechart ul.lvLast li + li {
  margin-left:  calc(8% / 3);
}


/* 마지막 레벨안에 텍스트들 */
#organization .treechart dl {
  /* border: 4px solid #29538d; */
  border: 4px solid #2d74d6;
  padding: 12px;
  color:white;
  border-radius: 8px;
  min-height: 10rem;
  min-width: 16em;
}
#organization .treechart dl:hover {
  /* transform: translateY(-10px); */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

#organization .treechart dt {
  /* background: #29538d; */
  background-color:#2d74d6;
  text-align: center;
  margin: -12px -12px 10px -12px;
  padding: 5px 2px;
  font-weight: 600;
}
#organization .treechart dd {
  padding: 3px 2px;
  color: #686868;
  font-size:0.88em;
  font-weight: 400;
}

#organization .treechart dd::before {
  content:"•";
  display: inline-block;
  color:#686868;
  margin-right: 5px;
}

/*****  선 그리기 따로씀 */
/* 1, 2lv 선 */
#organization .treechart .box.lv1::after,
#organization .treechart .box.lv2::after {
  left: 50%;
  top: 100%;
  width: 2px;
  height: 40px;
  background: #868686;
}

/* 기술총괄실 밑으로 */
#organization .treechart .lv3 .box::after {
  left: 50%;
  top: 100%;
  width: 2px;
  height: 40px;
  background: #868686;
}

/* 품질보증팀이랑 AI 연결하는 선 */
#organization .treechart .lv3 li:nth-child(2) .box::after {
  left: unset;
  top: unset;
  right: 51%;
  bottom: calc(100%);
  width: calc(100% + 100px);
  height: 20px;
  border: 2px dotted #868686 !important;
  border-bottom: none !important;
  border-left: none !important;
  background: none !important;
}

/* 클라우드 밑으로 선 지우기 */
#organization .treechart .lv4 li:nth-child(1) .box::after {
  background: #868686;
}
/* last lv 선 */
#organization .treechart .lvLast {
  position: relative;
}

#organization .treechart .lvLast::before {
  content:"";
  display: block;
  position: absolute;
  left: 12%;
  bottom: calc(100% + 18px);
  width: 76.5%;
  height: 2px;
  background: #868686;
}

#organization .treechart .lvLast  li{
  position: relative;
}

#organization .treechart .lvLast li::before {
  content:"";
  display: block;
  position: absolute;
  right: 50%;
  bottom: 100%;
  width: 2px;
  height: 20px;
  background: #868686;
}

#organization .treechart .lvLast li:first-child::before {
  display: none;
}